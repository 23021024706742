import React from 'react';
// components
import MlbChallengeMobile from './MlbChallengeMobile';
import MlbExecutionMobile from './MlbExecutionMobile';
import MlbHeroMobile from './MlbHeroMobile';
import MlbImpactMobile from './MlbImpactMobile';
import SectionTrigger from '../SectionTrigger';

function MlbContentMobile({ images, videos }) {
  const [
    mlbMobileImg01,
    mlbMobileImg02,
    mlbMobileImg03,
    mlbMobileImg04,
    mlbMobileImg05,
    mlbMobileImg06,
    mlbMobileImg07,
    mlbMobileImg08,
    mlbMobileImg09,
    mlbMobileImg10,
    mlbMobileImg11,
    mlbMobileImg12,
  ] = images;
  const [ mlbMobileVid01, mlbMobileVid02 ] = videos;

  return (
    <div>
      <SectionTrigger bgColor="#9f2828" textColor="#fff">
        <MlbHeroMobile images={[mlbMobileImg01]} />
      </SectionTrigger>
      <div className="mlb__container">
        <MlbChallengeMobile
          images={[mlbMobileImg02, mlbMobileImg03, mlbMobileImg04, mlbMobileImg05]}
          videos={[mlbMobileVid01]}
        />
        <MlbExecutionMobile
          images={[mlbMobileImg06, mlbMobileImg07, mlbMobileImg08, mlbMobileImg09]}
          videos={[mlbMobileVid02]}
        />
        <MlbImpactMobile
          images={[mlbMobileImg10, mlbMobileImg11, mlbMobileImg12]}
        />
      </div>
      <SectionTrigger
        bgColor="#9f2828"
        className="mlb-section mlb-section--1"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#a6ced6"
        className="mlb-section mlb-section--2"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#9f2828"
        className="mlb-section mlb-section--3"
        textColor="#fff"
      />
    </div>
  )
}

export default MlbContentMobile;