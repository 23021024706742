import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import VideoPlayer from 'src/components/VideoPlayer';

function MlbExecutionMobile({ images, videos }) {
  const [mlbMobileImg06, mlbMobileImg07, mlbMobileImg08, mlbMobileImg09] = images;
  const [mlbMobileVid02] = videos;

  return (
    <div className="mlb-execution">
      <Parallax className="mlb-execution__heading mlb-execution__heading--1" y={[0, 0]}>
      {/* <Parallax className="mlb-execution__heading mlb-execution__heading--1" y={[40, -40]}> */}
        <h3 className="execution">
          Execution
        </h3>
      </Parallax>
      <p className="mlb-execution__copy">
        Working closely with MLB, Getty and the athletes themselves we scoured thousands of images, personal and public to find the right moments to bring our script to life and then shot the players in uniform on their home field to bring forth a more intimate and personal take on stories that were so intimate and personal to them.
      </p>

      <h3 className="tv-social mlb-execution__heading mlb-execution__heading--2">
        TV & SOCIAL TV & SOCIAL
      </h3>

      <blockquote className="blockquote mlb-execution__blockquote mlb-execution__blockquote--2">
        &ldquo;… AND immensely fascinating character of individual MLB players.&rdquo;
      </blockquote>

      <div className="mlb__img mlb__img--6">
        <img src={mlbMobileImg06} alt="" />
      </div>
      <div className="mlb__img mlb__img--7">
        <img src={mlbMobileImg07} alt="" />
        <a
          className="main-copy"
          href="https://www.instagram.com/miketrout/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @miketrout
        </a>
      </div>
      <Parallax
        className="mlb__img mlb__img--8"
        y={[0, 0]}
        // y={[20, -20]}
      >
        <ParallaxImage src={mlbMobileImg08} alt="" />
        <a
          className="main-copy"
          href="https://www.instagram.com/javy23baez/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @javy23baez
        </a>
      </Parallax>

      <VideoPlayer
        className="mlb__vid mlb__vid--2"
        src={mlbMobileVid02}
        poster={mlbMobileImg09}
      />

      <p className="mlb-execution__tagline">
        <span>The Launch</span>
        “Power and Grace” national broadcast film
      </p>
    </div>
  );
}

export default MlbExecutionMobile;