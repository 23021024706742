import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/mlb.scss';
// hooks
import mlbMedia from 'src/hooks/mlbMedia';
import mlbMobileMedia from 'src/hooks/mlbMobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import MlbContent from 'src/components/mlb/MlbContent';
import MobileMlbContent from 'src/components/mlb/MlbContentMobile';

function MlbPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = mlbMobileMedia();
  const media = mlbMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  const vid01 =
    'https://player.vimeo.com/external/433467384.hd.mp4?s=1c404cb14900f1a456a5ad8f3844cabfc633dc53&profile_id=175&oauth2_token_id=1365998561';
  const vid02 =
    'https://player.vimeo.com/external/433467362.sd.mp4?s=f35c904f36eb79a2b3e071df7bf6aa3bdeb8f93e&profile_id=165&oauth2_token_id=1365998561';

  return (
    <div>
      <SEO title="I Just Wanna Play | Falkon Content" />
      <div className="mlb">
        {isDesktop
          ? (<MlbContent images={images} videos={[vid01, vid02]} />)
          : (<MobileMlbContent images={mobileImages} videos={[vid01, vid02]} />)
        }
      </div>
    </div>
  );
}

export default React.memo(MlbPage);
