import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import VideoPlayer from 'src/components/VideoPlayer';

function MlbChallenge({ images, videos }) {
  const [mlbImg02, mlbImg03, mlbImg04, mlbImg05] = images;
  const [mlbVid01] = videos;

  return (
    <div className="mlb-challenge">
      <h3 className="mlb-challenge__heading mlb-challenge__heading--1">
        Challenge
      </h3>

      <p className="main-copy mlb-challenge__copy mlb-challenge__copy--1">
        How do you create a campaign to celebrate two of MLB’s biggest stars, during its critical All-Star Week, while breaking through the increasing NOISE of sports marketing?
      </p>

      <div className="mlb__img mlb__img--2">
        <img src={mlbImg02} alt="" />
      </div>

      <Parallax
        className="mlb__img mlb__img--3"
        y={[0, 0]}
        // y={[20, -20]}
      >
        <ParallaxImage src={mlbImg03} alt="" />
      </Parallax>

      <h3 className="solution mlb-challenge__heading mlb-challenge__heading--2">
        Solution
      </h3>

      <p className="main-copy mlb-challenge__copy mlb-challenge__copy--2">
        We developed a larger brand strategy that digs deeply into the rich, diverse and immensely fascinating character of individual MLB players, dynamically juxtaposing the power of their larger than life talent on the field and their wildly unique and deeply humble character off it.
      </p>

      <p className="mlb-challenge__tagline">
        <span>The Launch</span>
        “Humble” national broadcast film
      </p>

      <Parallax
        className="mlb__img mlb__img--4"
        y={[0, 0]}
        // y={[20, -20]}
      >
        <ParallaxImage src={mlbImg04} alt="" />
      </Parallax>

      <VideoPlayer
        className="mlb__vid mlb__vid--1"
        src={mlbVid01}
        poster={mlbImg05}
      />
    </div>
  );
}

export default MlbChallenge;