import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';

function MlbImpact({ images }) {
  const [mlbImg10, mlbImg11, mlbImg12] = images;

  return (
    <div className="mlb-impact">
      <Parallax className="mlb-impact__heading" y={[0, 0]}>
      {/* <Parallax className="mlb-impact__heading" y={[40, -40]}> */}
        <h3 className="impact">
          Impact Impact
        </h3>
      </Parallax>
      <Parallax className="mlb-impact__copy" y={[0, 0]}>
      {/* <Parallax className="mlb-impact__copy" y={[40, -40]}> */}
        <p className="mlb-impact__copy-text">
          A campaign that extended beyond a single moment to become a long-term platform that celebrates the wide range of diverse players, redefining what a modern Sports Superstar can be, while wholly defining a radically fresh new voice for MLB.
        </p>
      </Parallax>
      <Parallax className="mlb-impact__blockquote" y={[0, 0]}>
      {/* <Parallax className="mlb-impact__blockquote" y={[40, -40]}> */}
        <blockquote className="blockquote mlb-impact__blockquote-text">
          They performed among the highest marketing assets the league has ever deployed.
        </blockquote>
      </Parallax>

      <div className="global-box-shadow mlb__img mlb__img--10">
        <img src={mlbImg10} alt="" />
      </div>

      <div className="global-box-shadow mlb__img mlb__img--11">
        <img src={mlbImg11} alt="" />
      </div>

      <div className="global-box-shadow mlb__img mlb__img--12">
        <img src={mlbImg12} alt="" />
      </div>
    </div>
  );
}

export default MlbImpact;