import React from 'react';
// components
import MlbChallenge from './MlbChallenge';
import MlbExecution from './MlbExecution';
import MlbHero from './MlbHero';
import MlbImpact from './MlbImpact';
import SectionTrigger from '../SectionTrigger';

function MlbContent({ images, videos }) {
  const [
    mlbImg01,
    mlbImg02,
    mlbImg03,
    mlbImg04,
    mlbImg05,
    mlbImg06,
    mlbImg07,
    mlbImg08,
    mlbImg09,
    mlbImg10,
    mlbImg11,
    mlbImg12,
  ] = images;
  const [ mlbVid01, mlbVid02 ] = videos;

  return (
    <div>
      <SectionTrigger bgColor="#9f2828" textColor="#fff">
        <MlbHero images={[mlbImg01]} />
      </SectionTrigger>
      <div className="mlb__container">
        <MlbChallenge
          images={[mlbImg02, mlbImg03, mlbImg04, mlbImg05]}
          videos={[mlbVid01]}
        />
        <MlbExecution
          images={[mlbImg06, mlbImg07, mlbImg08, mlbImg09]}
          videos={[mlbVid02]}
        />
        <MlbImpact images={[mlbImg10, mlbImg11, mlbImg12]} />
      </div>
      <SectionTrigger
        bgColor="#9f2828"
        className="mlb-section mlb-section--1"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#a6ced6"
        className="mlb-section mlb-section--2"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#9f2828"
        className="mlb-section mlb-section--3"
        textColor="#fff"
      />
    </div>
  )
}

export default MlbContent;