import React from 'react';
// components
import { MLBLogo } from 'src/components/svgs';

function MlbHero({ images }) {
  const [img01] = images;

  return (
    <div className="mlb-hero">
      <div className="mlb-hero__container">
        <div className="mlb-hero__header">
          <MLBLogo modifier="mlb-hero__header-logo" />
          <h3 className="main-heading mlb-hero__header-hdg">
            I JUST WANNA PLAY
          </h3>
          <p className="main-copy mlb-hero__header-copy">
            MAJOR LEAGUE BASEBALL
          </p>
        </div>
      </div>
      <img className="mlb-hero__img" src={img01} alt="baez1" />
    </div>
  )
}

export default MlbHero;