import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import VideoPlayer from 'src/components/VideoPlayer';

function MlbExecution({ images, videos }) {
  const [ mlbImg06, mlbImg07, mlbImg08, mlbImg09 ] = images;
  const [ mlbVid02 ] = videos;

  return (
    <div className="mlb-execution">
      <Parallax className="mlb-execution__heading mlb-execution__heading--1" y={[0, 0]}>
      {/* <Parallax className="mlb-execution__heading mlb-execution__heading--1" y={[40, -40]}> */}
        <h3 className="execution">
          Execution
        </h3>
      </Parallax>

      <p className="mlb-execution__copy">
        Working closely with MLB, Getty and the athletes themselves we scoured thousands of images, personal and public to find the right moments to bring our script to life and then shot the players in uniform on their home field to bring forth a more intimate and personal take on stories that were so intimate and personal to them.
      </p>

      <blockquote className="blockquote mlb-execution__blockquote mlb-execution__blockquote--1">
        &ldquo;brand strategy that digs deeply into the rich, diverse …&rdquo;
      </blockquote>

      <h3 className="tv-social mlb-execution__heading mlb-execution__heading--2">
        TV & SOCIAL TV & SOCIAL
      </h3>

      <blockquote className="blockquote mlb-execution__blockquote mlb-execution__blockquote--2">
        &ldquo;… AND immensely fascinating character of individual MLB players.&rdquo;
      </blockquote>


      <div className="mlb__img mlb__img--6">
        <img src={mlbImg06} alt="" />
      </div>

      <div className="mlb__img mlb__img--7">
        <a
          className="main-copy"
          href="https://www.instagram.com/miketrout/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @miketrout
        </a>
        <img src={mlbImg07} alt="" />
      </div>

      <Parallax
        className="mlb__img mlb__img--8"
        // y={[20, -20]}
        y={[0, 0]}
      >
        <ParallaxImage src={mlbImg08} alt="" />
        <a
          className="main-copy"
          href="https://www.instagram.com/javy23baez/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          @javy23baez
        </a>
      </Parallax>

      <p className="mlb-execution__tagline">
        <span>The Launch</span>
        “Power and Grace” national broadcast film
      </p>

      <VideoPlayer
        className="mlb__vid mlb__vid--2"
        src={mlbVid02}
        poster={mlbImg09}
      />
    </div>
  );
}

export default MlbExecution;